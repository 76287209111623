* {
  box-sizing: border-box;
}


/* Navbar customization */

.logoStyle{
  width: 200px;
  max-width: 100%;
}
.navcolor {
  background-color: #000;
  position: fixed;
  z-index: 23;
  width: 100%;
  max-width: 100%;
  opacity: 0.8;
}

.text-decoration {
  text-decoration: none;
  color: #fff
}

/* Carousel customization */
.carousel-item img {
  background-color: #000;

}

.carousel-caption {
  position: absolute;
  right: 15%;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
  top: 30%;
  bottom: 70%;
}

/* animation in carousel caption */
.carousel-control-prev,
.carousel-control-next,
.carousel-indicators {
  display: none;
}

.carousel-animation {
  width: 18ch;
  animation: typing 5s steps(22) infinite, blink .5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-family: monospace;
  font-size: 3.5em;
  -webkit-animation: typing 5s steps(22) infinite, blink .5s step-end infinite alternate;
  margin: 0 auto;
}

@keyframes typing {
  from {
    width: 0
  }
}

@keyframes blink {
  50% {
    border-color: transparent
  }
}

.Caption span {
  font-size: 25px;
  word-spacing: 3px;
  position: relative;
  top: 10px;
  display: inline-block;
  animation: bounce 1.5s infinite ;
  animation-delay: calc(.1s * var(--i));
  -webkit-animation: bounce 1.5s infinite ;
  
}

@keyframes bounce {
  100% {
    top: -20px;
    text-shadow: 0 1px #ccc
  }
}

.Caption span:nth-child(2) {
  animation-delay: 0.1s;
}

.Caption span:nth-child(3) {
  animation-delay: 0.2s;
}

.Caption span:nth-child(4) {
  animation-delay: 0.3s;
}

.Caption span:nth-child(5) {
  animation-delay: 0.4s;
}

.Caption span:nth-child(6) {
  animation-delay: 0.5s;
}

/*Junior front end developer caption design */

.container1 {
  display: flex;
  place-items: center;
  text-align: center;
  justify-content: center;
}

.title {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 2.2rem;
  text-transform: uppercase;
  display: flex;
  justify-items: center;
}


.title-word {
  animation: color-animation 4s linear infinite;
}

.title-word-1 {
  --color-1: #DF8453;
  --color-2: #3D8DAE;
  --color-3: #E4A9A8;
}

.title-word-2 {
  --color-1: #DBAD4A;
  --color-2: #ACCFCB;
  --color-3: #17494D;
}

.title-word-3 {
  --color-1: #ACCFCB;
  --color-2: #E4A9A8;
  --color-3: #ACCFCB;
}

.title-word-4 {
  --color-1: #3D8DAE;
  --color-2: #DF8453;
  --color-3: #E4A9A8;
}

@keyframes color-animation {
  0% {
    color: var(--color-1)
  }

  32% {
    color: var(--color-1)
  }

  33% {
    color: var(--color-2)
  }

  65% {
    color: var(--color-2)
  }

  66% {
    color: var(--color-3)
  }

  99% {
    color: var(--color-3)
  }

  100% {
    color: var(--color-1)
  }
}


/*Junior front end developer caption design ends here */






/* Contact info responsiveness */
.about-box {
  box-shadow: 5px 5px 15px 2px #efe9e9;
  margin-bottom: 10px;
  padding-bottom: 43px;
  padding-top: 25px;
  margin-top: 28px;
  background-color: #fff;
}

.About-skill-header {
  margin-right: 3px;
  margin-left: 3px;
}

.about-header {
  position: relative;
}

.about-header::after {
  position: absolute;
  content: "";
  width: 180px;
  background-color: #0d86f0;
  height: 2px;
  bottom: 0;
  left: 0;
  max-width: 100%;
  animation-name: lineABout;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;


}

 .myDescription{
  text-align: justify;
  text-justify: inter-word;
}

@keyframes lineABout {

  0% {
    width: 0;
    height: 2px;
  }

  100% {
    width: 180px;
    background-color: #0d86f0;
    height: 2px;

  }


}

.skillPic {
  text-align: center;
  margin-bottom: 10px;
  margin-top: 5px;
}

/*************************Services********************************/

.Cardgroup-services {
  box-shadow: 5px 5px 15px 2px #efe9e9;
  border: none;
  gap: 20px;
  margin-bottom: 30px;
}

.Cardgroup-services .Card-Services {
  box-shadow: 1px 2px 5px 1px #82c1f9;
  border: 0ch
}

.card-text{text-align: justify;}


/**********************************************Portfolio+***********************************************/
.portfolio {
  margin-bottom: 30px;
}

.portfolio-card {
  height: 550px;
}

.portfolio-body {
  border: 1px solid gray;
}

.portfolio-img {
  height: 300px;
  max-width: 100%;
  border: 1px solid gray;
  transition: all 0.9s;
  -webkit-transition: all 0.9s;
  -moz-transition: all 0.9s;
  -ms-transition: all 0.9s;
  -o-transition: all 0.9s;
}

.portfolio-img:hover {
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -ms-transform: scale(0.9);
  -o-transform: scale(0.9);
}




/**********************Contact*************/

.contact-background {
  box-shadow: 5px 5px 15px 2px #efe9e9;
  border: none;
  gap: 20px;
  margin-bottom: 30px;
  padding-bottom: 15px;
}





/***********************Media Queries****************************/
@media only screen and (max-width: 600px) {
  .carousel-animation {
    width: 18ch;
    animation: typing 5s steps(22) infinite, blink .5s step-end infinite alternate;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid;
    font-family: monospace;
    font-size: 1.5em;
    -webkit-animation: typing 5s steps(22) infinite, blink .5s step-end infinite alternate;
    margin: 0 auto;
  }

  .Caption span {
    font-size: 25px;
    word-spacing: 3px;
    position: relative;
    top: 10px;
    display: inline-block;
    animation: bounce 1.2s  infinite ;
    animation-delay: calc(.1s * var(--i));
    -webkit-animation: bounce 1.2s  infinite ;
    margin-top: 12px;
}

  @keyframes lineABout {

    0% {
      width: 0;
      height: 2px;
    }

    100% {
      width: 120px;
      background-color: #0d86f0;
      height: 2px;

    }


  }

  .portfolio-card {
    height: 650px;
  }

    .title {
      font-family: "Montserrat", sans-serif;
      font-weight: 800;
      font-size: 1.2rem;
      text-transform: uppercase;
      display: flex;
      justify-items: center;
    }

}

@media only screen and (max-width: 320px){
  .title {
      font-family: "Montserrat", sans-serif;
      font-weight: 800;
      font-size: 0.9rem;
      text-transform: uppercase;
      display: flex;
      justify-items: center;
    }
}


@media only screen and (max-width: 320px) {
  .title {
    font-family: "Montserrat", sans-serif;
    font-weight: 800;
    font-size: 0.9rem;
    text-transform: uppercase;
    display: flex;
    justify-items: center;
  }
}